#Home .saction{
    width: 100%;
    height: 100%;
    border-top: 2px solid rgb(78 184 248);
    border-left: 2px solid rgb(78 184 248);
    border-right: 2px solid rgb(0 0 0 / 15%);
    border-bottom: 2px solid rgb(0 0 0 / 15%);
    border-radius: 5px;
}
#Home .card{
    /* height: 100%; */
    border-top: 2px solid rgb(78 184 248);
    border-left: 2px solid rgb(78 184 248);
    border-right: 2px solid rgb(0 0 0 / 15%);
    border-bottom: 2px solid rgb(0 0 0 / 15%);
    border-radius: 5px;
}
#Home .card .card-header{
    width: 100%;
    height: 15rem;
    padding: 0;
    background-color: #e7f4ff;;
    position: relative;
}
#Home .card .card-img-top{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
#Home .card .input-group{
    width: 90%;
    height: 3rem;
    margin: auto;
    align-self: center;
    background-color: #fff;
}
#Home .card .input-group-prepend{
   align-content: center;
}

#Home .card .content{
    min-height: 13rem;
}
#Home .card .content2{
    min-height: 11.7rem;
}
.cl-box{
    width: 3rem;
    height: 3rem;
    background-color: #fff;
    border: 2px;
    align-content: center;
    border-radius: 50%;
    border: .5rem solid #c3e6fe;
    font-weight: bolder;
}

.cl-box-c{
    width: 4rem;
    height: 4rem;
    border: .5rem solid white;
    border-radius: 50%;
    box-shadow: 0 0 4px 0px rgb(0 0 0 / 26%);
    align-items: center;
    display: flex;
    place-content: center;
    margin-top: .3rem;
}
.cl-box-c.active {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0 9px 0px rgb(0 0 0 / 54%);
}
.cl-box-c.active .cl-box{
    width: 4rem;
    height: 4rem;
    font-size: 1.3rem;
    font-weight: bolder;
}
.cl-text{
    color:cornflowerblue;
    font-weight: bold;
    position: relative;
}
.shape{
    position: absolute;
    bottom: -46px;
    left: 26%;
    width: 39px;
    height: 46px;
    background-color: #e7f4ff;;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.cl-text:has(.cl-box-c.active) {
    color:royalblue;
}

.blue-box{
    background-color: #e7f4ff;;
}
.blue-box::before{
    /* content: ' ';
    width: 10px;
    height: 10px;
    background-color: red;
    display: block; */
}

.progress{
    width: 80px;
    height: 80px;
    line-height: 80px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}
.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress .progress-right{
    right: 0;
}
.progress .progress-right .progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}
.progress .progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: #ffffff;
    font-size: 16px;
    color: #2f98df;
    line-height: 72px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}
.progress.blue .progress-bar{
    border-color: #049dff;
}
.progress.blue .progress-left .progress-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
}
 
 .progress-left .progress-bar{
    animation: loading-3 1s linear forwards 1.8s;
}

 .progress-left .progress-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
}

 .progress-left .progress-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
}
@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        /* -webkit-transform: rotate(180deg);
        transform: rotate(180deg); */
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@media only screen and (max-width: 990px){
    .progress{ margin-bottom: 20px; }
}
.sr-box{
    margin: auto;
    text-align: center;
}
.button-group {
    display: flex;
    border: 2px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.toggle-button {
    padding: 10px 20px;
    border: none;
    background-color: #fff;
    cursor: pointer;
    flex: 1;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.toggle-button:not(:last-child) {
    border-right: 2px solid #ccc;
}

.toggle-button.active {
    background-color: #4CAF50;
    color: #fff;
}

.toggle-button:hover {
    background-color: #ddd;
}