@import "rsuite/dist/rsuite.css";
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Courier+Prime:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Courier+Prime:wght@400;700&family=Dancing+Script:wght@400;500;600;700&family=Edu+TAS+Beginner:wght@400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rethink+Sans:wght@400;500;600;700;800&family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

#page-local-report_completion_overview-index th.header {
  writing-mode: vertical-lr;
}

.img-container img{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 5px;
  overflow: hidden;
}
.p-con{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  /* background-color: #DFDBE5;
background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23aca3bb' fill-opacity='0.22' fill-rule='evenodd'/%3E%3C/svg%3E"); */
}
#cir1{
  position: absolute;
  top: -5rem;
  right: -5rem;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background-color: #073A5D;
  opacity: 0.2;
}


#cir2{
  position: absolute;
  bottom: -5rem;
  left: -5rem;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background-color: #073A5D;
  opacity: 0.3;

}

#cir3{
  position: absolute;
  top: 5rem;
  left: 15rem;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #FA4A78;
  opacity: 0.3;

}

#cir4{
  position: absolute;
  bottom: 5rem;
  right: 15rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #FA4A78;
  opacity: 0.3;

}

.p-signin{
  position: relative;
background-color: #fff;
/* box-shadow: 1px 2px 10px 5px rgba(0, 0, 0, 0.01); */
color: #000;
width: 390px;
padding: 1rem;
border-radius: 0.5rem;
/* color: #fff; */
border: 1px solid rgba(0, 0, 0, 0.2);
}
.p-signin .p-input{
  /* background-color: rgba(0, 0, 0, 0.1); */
  border-radius: 0.5rem!important;
  height: 50px;

}
.sidebar {
  overflow: hidden;
    width: 16rem !important;
}

.IconFormData {
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidebar .accordion-body {
  padding-left: 2px;
  padding-right: 2px;
}

.bg-gradient-architecture {
  background : #073A5D;
}

.sidebar .sidebar-brand{
  width: auto;
  height: auto;
}

.logo {
  padding: 10px;
  width: 90px;
  height: 90px;
}

.text-right {
  text-align: right;
}

.td-none {
  text-decoration: none;
}

.pr-170px {
  padding-right: 170px !important;
}

.mr-15 { 
  margin-right: 15px;
}

.p-cursor {
  cursor: pointer !important;
}

.modal.show {
  display: block;
}

.w-100pc {
  width: 100%;
}

.fs-10px {
  font-size: 10px;
}

.fs-12px {
  font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 20px;
}

a:hover, a:active, a:visited, a:focus {
  text-decoration: none;
}

.indent-18px {
  text-indent: 18px;
}

#accordionSidebar a {
  color: #F94A78  ;
  text-indent: 10px;
  padding: 5px;
  font-size: 13px;
}

#accordionSidebar a:hover {
  background: rgba(242, 243, 246, 1);
  border-radius: 10px;
  font-size: 13px;
}

#accordionSidebar a:active, #accordionSidebar a:focus {
  background: #073b5d13;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
}

.accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.menuIcon {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 10px;
}

.fg-theme {
  color: #F94A78;
  font-weight: 600;
}

.fg-red {
  color: #073A5D;
}


.fg-green {
  color: #1FAF24;
}

.btn-info {
  background-color: #ffffff;
  border-color: #4a90f9;
  outline: #4a90f9;
  color: #000;
}

.btn-info:hover{
  background-color: #4a90f9;
  border-color: #4a90f9;
  outline: #4a90f9;
  color: #fff;
}

.btn-info:active{
  background-color: #4a90f9;
  border-color: #4a90f9;
  outline: #4a90f9;
  color: #fff;
}

.btn-info:focus {
  background-color: #4a90f9;
  border-color: #4a90f9;
  outline: #4a90f9;
  color: #fff;
}

.btn-primary, .nav-pills .nav-link.active, .nav-pills .nav-link, .nav-pills .show>.nav-link {
  background-color: #ffffff;
  border-color: #F94A78;
  outline: #F94A78;
  color: #000;
}

.btn-primary:hover, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:hover {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn-primary:active, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:active {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn-primary:focus, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:focus {
  background-color: #F94A78;
  border-color: #F94A78;
  outline: #F94A78;
  color: #fff;
}

.btn .fas {
  color: inherit;
}

.form-select:focus, .form-select:active, .form-control:focus, .form-control:active {
  border-color: #b5b5b7;
  outline: 0;
  box-shadow: none;
}


.ml-15px {
  margin-left: 15px;
}
.ml-15 {
  margin-left: 15px;
}

.sweet-alert .confirm {
  background-color: #F94A78 !important;
}

.sweet-alert .cancel {
  background-color: #000000 !important;
}

.sweet-alert p {
  font-size: 25px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.err-msg {
  color: red;
}

.step-container {
  display: none;
}

.step-container.active {
  display: block;
}

.navbar .nav-item a.nav-link {
  color: #ffffff !important;
}

.navbar .nav-item a.nav-link span {
  color: #ffffff !important;
}

.navbar .nav-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navbar .nav-item a.nav-link:visited, 
.navbar .nav-item a.nav-link:focus, 
.navbar .nav-item a.nav-link:active, 
.navbar .nav-item a.nav-link:hover {
  background-color: transparent !important;
}

.navbar .nav-item a.nav-link span:visited, 
.navbar .nav-item a.nav-link span:focus, 
.navbar .nav-item a.nav-link span:active, 
.navbar .nav-item a.nav-link span:hover {
  background-color: transparent !important;
}

.mr-2 {
  margin-right: 10px;
}

.form-group {
  margin-top: 5px;
  margin-bottom: 5px;
}

.react-datepicker-wrapper {
  display: block;
  /* padding: 0; */
  /* border: 0; */
  width: 100%;
}

.react-ios-time-picker-input  {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.nav-item.dropdown .fas{
  color: #F94A78;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.simple .card-header {
  background-color: transparent;
}

.simple .card-footer {
  background-color: transparent;
}

.modal-body.simple {
  padding: 0;
}

.text-blue {
  color: #4e73df;
}


.brandLogoServer {
  width: 100px;
  height: 100px;
}

.small {
  font-size: 50%;
}

.float-right {
  float: right;
}

tr.active td {
  background-color: rgba(0, 255, 0, 0.1);
}

.providerDetails li {
  list-style: none;
  padding: 5px 10px;
  border: 1px solid #F94A78;
  border-radius: 5px;
  margin-top: 5px;
}

.providerDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 20px;
  padding: 0;
}

.locationDetails li {
  list-style: none;
  padding: 5px 10px;
  border: 1px solid #F94A78;
  border-radius: 5px;
  margin-top: 5px;
}

.locationDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 20px;
  padding: 0;
}

.form-group .form-label {
  width: 100%;
}

.form-group .rmdp-container  {
  width: 100%;
}

.form-group .rmdp-container .rmdp-input {
  width: 100%;
  padding: 5px 15px;
  height: auto;
}

.img-icon-activityList {
  width: 30px;
  height: 30px;
  /* margin-left: -30px;
  margin-top: -30px; */
  /* filter: blur(2px); */
}

.pallet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
  cursor: pointer;
}

.text-purple {
  color: #7a1faf;
}

.text-orange {
  color: #ffa200;
}

.text-theme {
  color: #F94A78;
}

.mt-4m {
  margin-top: -20px;
}

.ml-5m {
  margin-left: -25px;
}


.p-absolute {
  position: absolute;

}

.radius50 {
  border-radius: 50%;
}

.ml-2 {
  margin-left: 10px;
}


.redPallet {
  background-color: #ff0000;
}

.bluePallet {
  background-color: #0004ff;
}

.greenPallet {
  background-color: #3cff00;
}

.yellowPallet {
  background-color: #fff700;
}

.orangePallet {
  background-color: #ffaa00;
}

.skyPallet {
  background-color: #00fffb;
}

.limePallet {
  background-color: #00ff8c;
}

.purplePallet {
  background-color: #c800ff;
}

.pinkPallet {
  background-color: #ff46cb;
}

.blackPallet {
  background-color: #000000;
}

.whitePallet {
  background-color: #ffffff;
}

.ImagePallet img {
  /* width: 49.9%; */
  height: 120px;
  border-radius: 10px;
}
.ImagePallet {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ImagePallet:hover {
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}



/* Elearning */


.messageBox li {
  list-style: none;
}

.messageBox li.sender .SingleMessage {
  text-align: right;
  /* align-items: flex-start; */
  /* justify-content: right; */
  margin-right: 0;
  /* float: right; */
}

.messageBox li.receiver .SingleMessage {
  text-align: left;
  /* align-items: flex-end; */
  /* justify-content: left; */
  margin-left: 0;
}

.messageBox li .MSG {
  width: auto;
}

.messageBox li.sender .SingleMessage .MSG {
  float: right;
}

.messageBox li.receiver .SingleMessage .MSG {
  float: left;
}

.messageBox li .SingleMessage .MSG {
  position: relative;
}

.messageBox li.receiver .SingleMessage .MSG:before {
  position: absolute;
  margin-left: -15px;
  margin-top: 8px;
  border: 8px solid transparent;
  border-right-color: #d2d6de;
  content: ' ';
  height: 12px;
  width: 0;
  pointer-events: none;
}

.messageBox li.sender .SingleMessage .MSG:before {
  position: absolute;
  margin-right: -15px;
  margin-top: 8px;
  border: 8px solid transparent;
  border-left-color: #d2d6de;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}

.messageBox li .SingleMessage {
  width: 60%;
}

.box-image {
  height: 26px;
  width: auto;
}

.ml-5 {
  margin-left: 25px;
}

.h-calc2 {
  height: calc(100vh - 220px);
  overflow-y: scroll;
}

.rad-50pc {
  border-radius: 50%;
}

.img-circle {
  height: 50px;
}

.f-direction-column {
  flex-direction: column;
  margin: auto;
}

.centered-box {
  margin: auto;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.f-Roboto-Condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

.f-courior-prime {
  font-family:'Courier Prime', Courier, monospace;
}

.f-poppins {
  font-family:'Poppins', sans-serif;
}

.f-roboto {
  font-family: 'Roboto', sans-serif;
}

.f-Oswald {
  font-family: 'Oswald', sans-serif;
}

.f-Rethink-Sans {
  font-family: 'Rethink Sans', sans-serif;
}

.fg-cert-theme {
  color: #090E44;
}

.mr-1 {
  margin-right: 5px;
}

.serial-number-text {
  margin-top: -50px;
  margin-left: 120px;
  font-family: 'Poppins';
  color: #fff;
  font-size: 16px;
}
.certificate-date-text {
  margin-top: -71px;
  margin-left: 190px;
  font-family: 'Poppins';
  color: #fff;
  font-size: 16px;
}


.fs-16px {
  font-size: 16px;
}


.fs-20px {
  font-size: 20px;
}


.fs-12px {
  font-size: 12px;
}

.fs-40px {
  font-size: 40px;
}

.fs-30px {
  font-size: 30px;
}

.fs-25px {
  font-size: 25px;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

.h-400px {
  height: 800px;
}

.t-border3 {
  border-top: 3px solid #343434;
}

.b-border3 {
  border-bottom: 3px solid #343434;
}

.certificate {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: url('../src/assets/certificate.png') no-repeat;
  background-size: 100% 100%, cover, cover;
}

.certificate_new {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: url('../src/assets/certificate1.png') no-repeat;
  background-size: 100% 100%, cover, cover;
}

.certificate-container {
  width: 1100px;
  background-color: #ffffff;
}


.generateBTN {
  position: absolute;
}

.ml-1 {
  margin-left: 5px;
}

.pad-100px {
  padding: 50px;
}

.h-inherit {
  height: inherit;
}

.logo_style {
  height: 50px;
  margin: 50px 0;
}


.searchBoxContainer { 
  height: 350px;
  overflow-y: scroll;
}



.badge-primary {
  color: #666;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  border: 1px solid #aaa;
}


.StudentDetails li {
  list-style: none;
  padding: 5px 10px;
  border-bottom: 1px solid #F94A78;
  border-radius: 0px;
  /* border-radius: 5px; */
  margin-top: 5px;
}

.StudentDetails li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.CompanyUsers .card {
  border-color: #F94A78;
}

.CompanyDetails {
  padding: 0;
}

.CompanyDetails li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}



.courseList .card {
  border-color: #F94A78;
}

.courseList {
  padding: 0;
}

.courseList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.courseList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.courseList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.companyUserList .card {
  border-color: #F94A78;
}

.companyUserList {
  padding: 0;
}

.companyUserList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.companyUserList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.companyUserList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}



.companyList .card {
  border-color: #F94A78;
}

.companyList {
  padding: 0;
}

.companyList li {
  list-style: none;
  /* padding: 5px 10px; */
  /* border: 1px solid #F94A78; */
  border-radius: 5px;
  margin-top: 5px;
}

.companyList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}


.companyList li:first-child {
  border: none;
  color: #F94A78;
  font-size: 16px;
  padding: 0;
}

.w-80px {
  width: 80px;
}

.w-80pc {
  width: 80vh;
}

.h-90pc {
  height: 90vh;
}

.pm0 {
  padding: 0;
  margin: 0;
}

.searchBox {
  border-radius: 20px;
  overflow: hidden;
}

.searchBox input {
  border-radius: 20px;
  border: none;
}

.searchBox .input-group {
  padding: 0 10px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0);
  z-index: 999999;
  border: none;
}

.searchBox .input-group-text {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0);
  border: none;
}

.no-border {
  border: none;
}


.z999 {
  z-index: 999999;
  width: calc(100% - 270px)
}


/* Elearning */




.x-scroll {
  overflow-x: scroll;
}

.slot {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}


.p-nav-main{
  padding-left: 0;
  color: #ddd;
  list-style-type:none;
  width: 100%;

  /* background-color: rgba(0, 0, 0, 0.7); */
}
.p-nav-main li{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  /* border-radius: 0.3rem; */
  padding: 0.8rem 2rem;
  /* width: 100%; */
  cursor: pointer;
  border-top: 1px solid rgba(242, 243, 246, 0.5);
  /* border-bottom: 1px solid rgba(242, 243, 246, 0.7); */
  color: #fff;

}
.p-nav-main li a{
  color: #e7e7e7;

}
.p-nav-main li i{
  font-size: 1.3rem;
  color: #e7e7e7;
  /* color: #073A5D; */
}

/* .p-nav-main li:hover{
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
} */

/* .p-nav-main li:hover i{
  
  color: #fff;
} */

.p-active li{
  background-color: rgba(242, 243, 246, 1);
  color:  #073A5D!important;
  /* width: 100%;
  width: 100px;
  height: 50px; */

  
  
}
.p-active li i{
  color:  #073A5D!important;

}
.p-pending li{
  background-color:  #073A5D;
  color: rgba(242, 243, 246, 1);
}
.p-pending li:hover{
  background-color: rgba(0, 0, 0, 0.2);
}
/* .p-nav-main li:hover i{

  color:  #073A5D;
} */

.logout-p{
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  
}
.logout-p:hover {
  background-color:#FA4A78;

}
.logout-p span{
  font-size: 0.9rem;
  display: flex;
  align-items: center;

}
.logout-p span #sign_o{
  font-size: 1.2rem;
}
.doc-p h3{
  margin: 1rem 0;
}
.doc-p .doc-inner{
border-top: 1px solid rgba(0, 0, 0, 0.1);

}
.doc-p .doc-inner input{
  margin-top: 0.5rem;
  width: 400px;
  padding: 0.8rem;
}

.doc-p .docs-con{
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-p{
  padding: 0.5rem 1.4rem;
  background-color: #fa4a78;
  border-radius: 0.3rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.9rem;
}
.reports-sec .rep-card{
  margin: 0.9rem 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

}
.reports-sec .rep-card .card .card-l div{
font-size: 2rem;

}
.reports-sec .rep-card .card{

  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 350px;
  display: flex;
  align-items: flex-start;

  color: #073A5D;
 


}


.reports-sec .rep-card .card .card-r{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
.reports-sec .rep-card .card .card-r h3{
  background-color: #073A5D;
}
.reports-sec .rep-card .card .card-r p{
margin: 0.3rem 0;
}

.reports-sec .rep-card .card .card-r button{
  /* margin: 0.3rem 0; */
  color: #FA4A78;
  }


.history td{
  align-content: center;
}
.collapsed .fa.fa-angle-down::before{
  content: "\f106";
  }
  .Toastify{
    z-index: 99999999 !important;
  }
  .breadcam-icon{
    --bs-breadcrumb-divider: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E);
  }
  .breadcrumb{
    margin-bottom: 0;
  }
  .img-upload-box{
    position: relative;
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center !important;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 4px;
    line-height: 20px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    border: 1px dashed rgba(17, 17, 17, 0.2);
    padding: 3rem;
    margin-bottom: 1rem;
  }

  .progressBar{
    width: 90%;
    margin-top: 1rem;
  }
  .file {
    background: #307CF1;
    border-radius: 5px 0 0 0;
    position: relative;
    height: 100px;
    width: 75px;
  }
  .file::before,
  .file::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
  }
  .file::before {
    border-bottom: 25px solid #78AAF6;
    border-right: 25px solid transparent;
    border-bottom-left-radius: 5px;
  }
  .file::after {
    border-bottom: 25px solid transparent;
    border-right: 25px solid white;
  }
  .file__type {
    position: absolute;
    bottom: 5px;
    left: 7.5px;
    color: white;
    font-family: Arial;
  }
  .rbc-month-row{
    overflow: visible !important;
  }
  input[name=search] {
    background-image: url(https://www.w3schools.com/howto/searchicon.png);
    background-position: 10px 12px;
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    -webkit-transition: width .4s ease-in-out;
    transition: width .4s ease-in-out;
}
.popup-content {
  background-color: #292d3e !important;
  border: 1px solid #464545 !important;
  color: #fff !important;
  z-index: 999999 !important;
  width: 300px !important;
}
.popup-arrow {
  stroke: #464545 !important;
  color: #292d3e !important;
}