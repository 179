.CloseIcon {
    margin-top: -10px;
    margin-left: 20px;
    position: absolute;
}

.content-space-between {
    justify-content: space-between;
}

.btn-default {
    background-color: #f1f1f1 !important;
}