.panel-tab{
    font-weight: 400;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.panel-tab.active{
    font-weight: 600;
    border-bottom: 2px solid #073a5d;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.custom-tab{
    border: 1px solid #aaa;
    color: #004ba3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.custom-tab .nav-item.active{
    color: #3c00ff;
}
.custom-tab-content{
    border: 1px solid #aaa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.b-right{
    border-right: 1px solid #aaa;
}
.text-size-p{
    font-size: 14px;
    line-height: 1.42857143;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.accordion-button{
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e10179'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e10179'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
}
.custom-accordian::after{
    border: 1px solid #ff00004a;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    background-position: center;
}
.custom-accordian:not(.collapsed) {
    --bs-accordion-active-bg: transparent;
    --bs-accordion-border-color: transparent;
}
.cfw-Normal{
    font-weight: 400;
}
.custom-box-overtime{
    height: 250px;
}
.custom-box-overtime span{
    font-size: 12px;
    font-weight: bold;
    color: #aaa;
}
.h-box{
    background-color: #0000ff05;
    border-bottom: 1px solid #0000ff18;
}
.h-box:last-child{
    border-bottom: none;
}
.custom-trip-table thead tr{font-size: 0.9rem}
.custom-trip-table thead tr th{
    color: #073a5d;
    background-color: #EBF7FF;
    border-bottom: 2px solid rgba(0,0,0,0.2);
}