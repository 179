.modal {
    z-index: 9999999 !important;
}

.h60vh {
    height: 60vh;
}

.rad20px {
    border-radius: 0px 0px 20px 20px;
}


.scheduled {
    padding: 5px;
    position: absolute;
    width: 105px;
    background-color: #ff0000cc;
    color: #fff;
}

.schedular-table td {
    padding: 0;
}

.schedular-table td:first-child {
    padding: 5px;
}

.w-70pc {
    width: 70%;
}
.bg-white {
    background-color: #fff;
}
.pad-5-25 {
    padding: 5px 25px;
}

.leftBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999999;
}
.topBar {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999999;
    max-height: auto;
    height: auto;
}
.rightBar {
    position: fixed;
    top: 0;
    right: 0;
    width: 15%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999999;
}

.schedular-table {
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 999999;
}

/* Add this CSS for the tooltip */
.schedular-table .tooltip {
    /* position: absolute; */
    text-align: center;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    z-index: 999999;
}


.btn.btn-primary.active.online_employee {
    background-color: #1d46b5;
}
.btn.btn-primary.active.offline_employee {
    background-color: rgb(185 75 26);
}
.btn.btn-primary.active.online_service_user {
    background-color: #9003fc;
}
.btn.btn-primary.active.offline_service_user {
    background-color: #fc9803;
}


.map-markers .tooltip-custom-test {
    background: white !important;
    padding: 5px;
    box-sizing: content-box;
    width: 100px;
    margin: 0 -40px;
    text-align: center;
    align-items: center;
    justify-content: center;
}