.emp_p .emp_p_r{
    padding: 0.5rem;
    margin-top: 1.6rem;

}

.emp_p .emp_p_r .btns-emp{
border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.emp_p .emp_p_r .btns-emp button{
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    color: #073A5D;
    /* transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}
.accordion-bt:not(.collapsed) .accordion-button::after{
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}