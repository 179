.truncated-paragraph {
  max-height: 40px;
  overflow: hidden;
  position: relative;
}

.truncated-paragraph.expanded {
  max-height: none !important;
}
.truncated-paragraph p {
  margin: 0;
  padding: 0;
}

.read-more {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  margin-top: 5px;
}
