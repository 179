.emp_p .emp_p_r{
    padding: 0.5rem;
    margin-top: 1.6rem;

}

.emp_p .emp_p_r .btns-emp{
border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.emp_p .emp_p_r .btns-emp button{
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    color: #073A5D;
    /* transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

.btn-p{
    padding: 0.5rem 1.4rem;
    background-color: #fa4a78;
    border-radius: 0.3rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 1rem 0;
}
.cards-emp{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;
    width: 100%!important;
}
.cards-emp .card-p{
    display: flex;
    align-items: center;
    border:1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    /* width: 240px; */
    justify-content: flex-start;
    padding: 0 0.5rem!important;
    margin: 0!important;
    /* height: 130px; */
    margin: 0;
    gap: 0.5rem;
    padding: 0.4rem!important;
}
.cards-emp .card-p .Avatar{
    width: 55px;
    height: 55px;
    background-color: #073A5D;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.cards-emp .card-p div:nth-child(2){
    /* margin: 0!important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
}

.cards-emp .card-p div:nth-child(2) h3{
    font-size: 1rem;
}

.p-add-emp{
   width: 100%;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.3);
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

.p-add-emp .emp-body{
    width: 700px;
    background-color: #fff;
    border-radius: 0.5rem;
    
}

.p-add-emp .emp-body h4{
    padding: 1.1rem 1.2rem;
    background-color: #073A5D;
    color: #fff;
    font-size: 1.1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;


 }

 .p-add-emp .emp-body .emp-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;
    gap: 0.8rem;
    padding: 1rem 1.8rem;
 }

 .p-add-emp .emp-body .emp-form div{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
 }

 .p-add-emp .emp-body .emp-form div input{
    width: 640px;
    height: 40px;
    padding: 0.5rem;
    border:1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
   
 }
 