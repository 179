.cal-con{
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 0.8rem!important;
    padding: 1rem 0.8rem!important;
}
.calendar-style{
 width: 100%;
 box-sizing: border-box;
 height: 400px;
 /* display: flex;
 flex-direction: column;
 align-items: stretch;
 position: absolute; */
 color: #073A5D;
}
.rbc-date-cell{
    margin-top: 0.3rem!important;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    padding: 0!important;

}
.rbc-row-bg{
    height: 80px;
}
.rbc-month-view{
margin-top: 0rem!important;
padding: 0!important;
}
.rbc-month-row{
margin-top: 0rem!important;
height: 80px!important;
flex:0.6 0;
flex-basis: 10px;
}
.rbc-header{
    margin-top:0 !important;
    height: 40px;
    font-size: 0.9rem;
    padding: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#073A5D ;
    color: #fff;
    /* width: 125px!important; */
    /* border-left: none!important; */
}
.rbc-row-content{
    margin-top: 0!important;
    padding: 0!important;
}
.rbc-time-header-content{
margin-top: 0;
}

.rbc-row{
    /* border:1px solid rgba(0, 0, 0, 0.1); */
}
/* .calendar-style .rbc-calendar {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
.calendar-style .rbc-month-header {
    background-color: #f0f0f0;
    color: #333;
  } */